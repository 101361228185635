<template>
  <v-list-item dense v-bind="$attrs" v-on="$listeners">
    <!-- Avatar -->
    <v-list-item-icon v-if="hasSlot('avatar')">
      <slot name="avatar"></slot>
    </v-list-item-icon>

    <!-- title & subtitle -->
    <v-list-item-content>
      <!-- :class="titleClass"-->
      <v-list-item-title :style="wrappedText" :class="titleClass">
        <slot name="default">
          {{ title }}
        </slot>
      </v-list-item-title>

      <v-list-item-subtitle v-if="hasSlot('subtitle')">
        <slot name="subtitle"></slot>
      </v-list-item-subtitle>
    </v-list-item-content>

    <!-- actions -->
    <v-list-item-action v-if="hasSlot('actions')">
      <slot name="actions"></slot>
    </v-list-item-action>
  </v-list-item>

  <!-- Custom bootstrap with subtitle -->
  <!--
   <div>
   <div class="d-flex justify-space-between align-center">
    <div class="d-flex align-center">
      <div class="mr-3">
        <slot name="avatar"></slot>
      </div>
      <div :class="titleClass">
        <slot name="default">{{ title }}</slot>
      </div>
    </div>
      <slot name="actions"></slot>
  </div>
   Add this for subtitle
  <div class="ml-5 mt-1" v-if="hasSlot('subtitle')">
    <slot name="subtitle"></slot>
  </div>
  </div>
  -->
</template>

<script>
// services
import { titleClass } from "@/services/text/textService";

// design
import { wrappedText } from "@/design/styles/styleConst";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "DetailsBannerLayout",
  inheritAttrs: false,
  mixins: [menuItemSettingsMixin, slottableMixin],
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      /**
       * wrapped Text style
       * which allows the text to wrap and take up multiple lines.
       * @type {string}
       */
      wrappedText: wrappedText
    };
  },
  computed: {
    /**
     * title Class
     * @return {string}
     */
    titleClass() {
      return titleClass();
    }
  }
};
</script>
